/* eslint-disable react-hooks/exhaustive-deps */
import useLoading from "@/hooks/useLoading";
import investServices from "@/services/apiServices/investServices";
import useUser from "@/store/useUser";
import { Row, Skeleton } from "antd";
import { useEffect, useState } from "react";

const LinkList = () => {
  const [linkList, setLinkList] = useState([]);
  const { data: userData } = useUser();
  const { executeAction: getLinkData, loading: isLoading } = useLoading(
    async () => {
      const data = await investServices.getFileLinks(
        userData?.data?.settings?.spreadsheet_url,
      );
      if (data?.data?.data) {
        setLinkList(data?.data?.data);
      }
    },
  );

  useEffect(() => {
    getLinkData();
  }, []);

  const LinkChildView = ({ name, value }) => (
    <div key={name}>
      <strong>{name} : </strong>{" "}
      <a target="_blank" href={value} rel="noreferrer">
        {value}
      </a>
    </div>
  );
  return (
    <Row className="pl-[20px]">
      {isLoading ? (
        <Skeleton key={1} paragraph={{ rows: 0 }} />
      ) : (
        linkList?.map((item, index) => (
          <div className="mb-5" key={index}>
            {Object.entries(item).map(([key, value]) => (
              <LinkChildView key={key} name={key} value={value} />
            ))}
          </div>
        ))
      )}
    </Row>
  );
};

export default LinkList;
