import { Button, message, Row } from "antd";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { setAccessToken } from "@/utils/index.js";
import apiClient, {
  setAxiosInstanceHeaders,
} from "@/services/apiClient/index.js";
import userService from "@/services/apiServices/userService/index.js";
import useLoading from "@/hooks/useLoading.js";
import Google from "@/assets/google.svg";

const Login = () => {
  const navigate = useNavigate();

  const { executeAction: fetchUserData, loading: isUserLoading } = useLoading(
    async (authResponse) => {
      try {
        const data = await userService.getUserToken(authResponse?.code);
        if (data?.data?.error) {
          message.error(data?.data?.error);
          return;
        }
        setAccessToken(data?.data?.data?.access_token);
        setAxiosInstanceHeaders(apiClient);
        message.success("Login Successfully.");
        navigate("/overview");
      } catch {
        setAccessToken("");
        localStorage.clear();
      }
    },
  );

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => fetchUserData(codeResponse),
    onError: (error) => {
      if (typeof error?.error === "string") message.error(error?.error);
      else console.error(error);
    },
    onNonOAuthError: (error) => console.error(error?.error),
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/spreadsheets",
  });

  return (
    <Row className="items-center justify-center text-center min-h-[100vh]">
      <div className="flex flex-col justify-center items-center">
        <Row>
          <Button
            icon={<Google className="mr-2 hight-[32px] width-[32px]" />}
            className="flex justify-center items-center p-6"
            onClick={login}
            loading={isUserLoading}
          >
            Sign in with Google
          </Button>
        </Row>
      </div>
    </Row>
  );
};

export default Login;
