import useLoading from "@/hooks/useLoading.js";
import environment from "@/environment/index.js";
import apiClient from "@/services/apiClient/index.js";
import { Button, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import usePositions from "@/store/usePositions.js";

const UploadPosition = ({ onSuccess, positionDate }) => {
  const { mutate: mutatePositionData } = usePositions();
  const {
    executeAction: handleUploadPositionFile,
    loading: isUploadPositionFileLoading,
  } = useLoading(async (fileList) => {
    try {
      const formData = new FormData();
      formData.set("positions_file", fileList);
      formData.set("positions_date", positionDate);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const url = `${environment.BASE_URL}/api/files/upload/position_file`;
      await apiClient.post(url, formData, config);
      message.success("Submit Successful.");
      await onSuccess?.();
      await mutatePositionData?.();
    } catch (e) {
      message.error(
        typeof e?.response?.data?.message === typeof String()
          ? e?.response?.data?.message
          : "Something went wrong. Please try again in some time.",
      );
    }
    return false;
  });

  const props = {
    accept: ".csv",
    multiple: false,
    beforeUpload: async (file) => {
      await handleUploadPositionFile(file);
      return false;
    },
  };

  return (
    <Upload {...props} showUploadList={false} disabled={!positionDate}>
      <Button
        icon={<UploadOutlined />}
        disabled={!positionDate}
        loading={isUploadPositionFileLoading}
      >
        Upload Position file
      </Button>
    </Upload>
  );
};

export default UploadPosition;
