import { Button, Collapse, Row, theme, Typography } from "antd";
import dayjs from "dayjs";
import investServices from "@/services/apiServices/investServices/index.js";
import { CaretRightOutlined } from "@ant-design/icons";
import CustomTable from "@/components/CustomTable/index.jsx";
import useSWR from "swr";

const StockScreener = ({ submitScreener, isScreenerLoading }) => {
  const { token } = theme.useToken();
  const panelStyle = {
    border: "none",
  };

  const {
    data: metricsData,
    isLoading: isMetricsDataLoading,
    mutate: mutateMetricsData,
  } = useSWR(`fetchStockScreener`, investServices.getStockScreener);

  const screenerHeaders = [
    {
      title: "Runtime",
      dataIndex: "created_on",
      render: (value) => dayjs(value)?.format("HH:mm DD/MM/YYYY"),
    },
    {
      title: "View",
      dataIndex: "id",
      render: (_, record) => (
        <Button
          type="link"
          href={record?.files?.[0]?.spreadsheet_url}
          target={"_blank"}
          className="p-0 m-0"
        >
          open
        </Button>
      ),
    },
  ];

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <Typography.Text className="text-[16px] font-semibold mb-[20px]">
          History
        </Typography.Text>
      ),
      children: (
        <Row className="w-full flex-col">
          <CustomTable
            isTableLoading={isMetricsDataLoading}
            tableHeaders={screenerHeaders}
            tableData={metricsData?.data?.data || []}
            onRowClick={() => {}}
          />
        </Row>
      ),
      style: panelStyle,
    },
  ];

  return (
    <>
      <Button
        type="primary"
        className="mr-4 bg-primaryDark"
        onClick={async () => {
          await submitScreener("StockScreener");
          await mutateMetricsData();
        }}
        loading={isScreenerLoading}
      >
        Run
      </Button>
      <Button
        href={metricsData?.data?.data?.[0]?.files?.[0]?.spreadsheet_url}
        disabled={!metricsData?.data?.data?.[0]?.files?.[0]?.spreadsheet_url}
        target={"_blank"}
        className="mr-2"
      >
        Open recent execution file
      </Button>
      <Collapse
        bordered={false}
        className="mt-[20px]"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: token.colorBgContainer,
        }}
        items={getItems(panelStyle)}
      />
    </>
  );
};

export default StockScreener;
