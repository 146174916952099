import useLoading from "@/hooks/useLoading";
import investServices from "@/services/apiServices/investServices";
import useUser from "@/store/useUser";
import { Button, Row, Select, Skeleton, Typography, message } from "antd";
import { useState } from "react";

const PerformanceLinkList = () => {
  const stockList = [
    {
      value: "KS",
      label: "KS",
    },
    {
      value: "Index",
      label: "Index",
    },
    {
      value: "",
      label: "Portfolio",
    },
  ];
  const [linkList, setLinkList] = useState([]);
  const [pcStock, setPcStock] = useState("");
  const { data: userData } = useUser();
  const { executeAction: getLinkData, loading: isLoading } = useLoading(
    async () => {
      try {
        if (
          !userData?.data?.settings?.start_date ||
          !userData?.data?.settings?.end_date ||
          !userData?.data?.settings?.spreadsheet_url
        ) {
          message.error("Please populate settings values");
          return true;
        }
        let params = {
          start_date: userData?.data?.settings?.start_date || "",
          end_date: userData?.data?.settings?.end_date || "",
          spreadsheet_url: userData?.data?.settings?.spreadsheet_url || "",
        };
        if (pcStock) {
          params.stock_group = pcStock;
        }
        const data = await investServices.getPerformanceFileLinks(params);
        if (data?.data?.data) {
          setLinkList(data?.data?.data);
        } else {
          setLinkList([]);
        }
      } catch (e) {
        message.error(
          typeof e?.response?.data?.message === typeof String()
            ? e?.response?.data?.message
            : "Something went wrong. Please try again in some time.",
        );
      }
    },
  );
  const LinkChildView = ({ name, value }) => (
    <div key={name}>
      <strong>{name} : </strong>{" "}
      <a target="_blank" href={value} rel="noreferrer">
        {value}
      </a>
    </div>
  );
  return (
    <Row className="pl-[20px] flex flex-col">
      <Row className="items-center mt-[10px] justify-between  w-[500px]">
        <Typography.Text className="text-[#696969]">
          <span className="text-red-700"></span>Stock Group
        </Typography.Text>

        <Select
          value={pcStock}
          placeholder={"Select Stock"}
          onChange={setPcStock}
          className="w-[256px]"
          options={stockList}
        />
      </Row>
      <Row className="items-center mt-[20px] mb-[20px]">
        <Button
          type="primary"
          className="mr-4 bg-primaryDark"
          onClick={getLinkData}
          loading={isLoading}
        >
          Get Data
        </Button>
      </Row>
      {isLoading ? (
        <Skeleton key={1} paragraph={{ rows: 0 }} />
      ) : (
        linkList?.map((item, index) => (
          <div className="mb-5" key={index}>
            {Object.entries(item).map(([key, value]) => (
              <LinkChildView key={key} name={key} value={value} />
            ))}
          </div>
        ))
      )}
    </Row>
  );
};

export default PerformanceLinkList;
