import { Button, Typography } from "antd";
import CustomTable from "@/components/CustomTable/index.jsx";
import useTransactions from "@/store/useTransactions.js";
import UploadTransaction from "@/components/UploadTransaction/index.jsx";
import environment from "@/environment/index.js";
import dayjs from "dayjs";

const TransactionFiles = () => {
  const {
    data: transactionData,
    mutate: mutateTransactionData,
    isLoading,
  } = useTransactions();

  const fileHeaders = [
    {
      title: "Date of upload",
      dataIndex: "uploaded_on",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      render: (value) => (
        <Typography.Text className="text-[14px] font-normal">
          {dayjs(value).format("YYYY-MM-DD")}
        </Typography.Text>
      ),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      render: (value) => (
        <Typography.Text className="text-[14px] font-normal">
          {dayjs(value).format("YYYY-MM-DD")}
        </Typography.Text>
      ),
    },
    {
      title: "View",
      dataIndex: "file_path",
      render: (value) => (
        <Button
          type="link"
          href={`${environment.BASE_URL}static/${value}`}
          target={"_blank"}
          className="p-0 m-0"
        >
          Open file
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="mb-[20px]">
        <UploadTransaction onSuccess={mutateTransactionData} />
      </div>
      <CustomTable
        isTableLoading={isLoading}
        tableHeaders={fileHeaders}
        tableData={transactionData?.data?.data || []}
      />
    </>
  );
};

export default TransactionFiles;
