/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import useLoading from "@/hooks/useLoading.js";
import dayjs from "dayjs";
import useUser from "@/store/useUser.js";
import investServices from "@/services/apiServices/investServices";
import { CloseCircleOutlined } from "@ant-design/icons";

const StockPrice = () => {
  const [userSetting, setUserSetting] = useState({
    start_date: null,
    end_date: null,
    spreadsheet_url: "",
  });
  const [pcStock, setPcStock] = useState("");
  const [priceSheetData, setPriceSheetData] = useState("");
  const [timeFrame, setTimeFrame] = useState("");

  const [stockList, setStockList] = useState([]);
  const timeList = [
    { value: "", label: "Select Time Frame" },
    { value: "daily", label: "Daily" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
  ];

  const [customStock, setCustomStock] = useState("");

  const { data: userData } = useUser();
  const { executeAction: getStockDetails, loading: stockDetailsLoader } =
    useLoading(async () => {
      const stockData = await investServices.getAllStockList();
      if (stockData?.data?.data) {
        let dataArr = [
          {
            value: "",
            label: "Select Stock",
          },
        ];

        stockData?.data?.data?.map((item) => {
          dataArr.push({
            value: item?.stock,
            label: item?.stock,
            ...item,
          });
        });
        dataArr.push({
          value: "custom",
          label: "Type your Stock",
        });

        setStockList(dataArr);
      }
    });

  const { executeAction: getStockPrice, loading: stockPriceLoader } =
    useLoading(async () => {
      try {
        const params = `start_date=${dayjs(userSetting.start_date).format(
          "YYYY-MM-DD",
        )}&time_frame=${timeFrame}&ticker=${
          pcStock == "custom" ? customStock : pcStock
        }&end_date=${dayjs(userSetting.end_date).format(
          "YYYY-MM-DD",
        )}&spreadsheet_url=${userSetting.spreadsheet_url}`;
        const stockData = await investServices.getStockPrice(params);
        if (stockData?.data?.success == true) {
          message.success(stockData?.data?.message || "Submit Successful.");
          setPriceSheetData(stockData.data.spreadsheet_url);
        } else {
          message.error(
            stockData?.data?.message ||
              "Something went wrong. Please try again in some time.",
          );
        }
      } catch (e) {
        message.error(
          typeof e?.response?.data?.message === typeof String()
            ? e?.response?.data?.message
            : "Something went wrong. Please try again in some time.",
        );
      }
    });

  useEffect(() => {
    getStockDetails();
  }, []);

  useEffect(() => {
    if (userData?.data?.settings) {
      setUserSetting({
        ...userData?.data?.settings,
        start_date: dayjs(userData?.data?.settings?.start_date),
        end_date: dayjs(userData?.data?.settings?.end_date),
      });
    }
  }, [userData]);

  const LinkChildView = () => (
    <div className="mt-[20px]">
      <strong>Spreadsheet Link : </strong>{" "}
      <a target="_blank" href={priceSheetData} rel="noreferrer">
        {priceSheetData}
      </a>
    </div>
  );

  return (
    <>
      <Row className="flex-col ml-4 w-[500px]">
        <Row className="items-center mt-[10px] justify-between">
          <Typography.Text className="text-[#696969]">
            <span className="text-red-700">*</span>Start Date
          </Typography.Text>
          <DatePicker
            className="w-[256px]"
            value={userSetting.start_date}
            onChange={(value) =>
              setUserSetting({ ...userSetting, start_date: value })
            }
          />
        </Row>
        <Row className="items-center mt-[20px] justify-between">
          <Typography.Text className="text-[#696969]">
            <span className="text-red-700">*</span>End Date
          </Typography.Text>
          <DatePicker
            className="w-[256px]"
            value={userSetting.end_date}
            onChange={(value) =>
              setUserSetting({ ...userSetting, end_date: value })
            }
          />
        </Row>
        <Row className="items-center mt-[20px] justify-between">
          <Typography.Text className="text-[#696969]">
            <span className="text-red-700">*</span>Google Spreadsheet URL
          </Typography.Text>
          <Input
            placeholder="https://docs.google.com/spreadsheets/"
            className="w-[256px]"
            value={userSetting.spreadsheet_url}
            onChange={(e) =>
              setUserSetting({
                ...userSetting,
                spreadsheet_url: e.target.value,
              })
            }
          />
        </Row>
        <Row className="items-center mt-[20px] justify-between">
          <Typography.Text className="text-[#696969]">
            <span className="text-red-700">*</span>Stock Symbol
          </Typography.Text>

          {pcStock != "custom" ? (
            <Select
              value={pcStock}
              placeholder={"Select Stock"}
              onChange={setPcStock}
              className="w-[256px]"
              options={stockList}
            />
          ) : (
            <Input
              placeholder="Stock Name"
              addonAfter={
                <CloseCircleOutlined
                  onClick={() => {
                    setCustomStock("");
                    setPcStock("");
                  }}
                />
              }
              className="w-[256px]"
              value={customStock}
              onChange={(e) => setCustomStock(e.target.value)}
            />
          )}
        </Row>
        <Row className="items-center mt-[20px] justify-between">
          <Typography.Text className="text-[#696969]">
            <span className="text-red-700">*</span>Time Frame
          </Typography.Text>

          <Select
            value={timeFrame}
            placeholder={"Select Stock"}
            onChange={setTimeFrame}
            className="w-[256px]"
            options={timeList}
          />
        </Row>
        <Row className="items-center mt-[20px]">
          <Button
            type="primary"
            disabled={
              !userSetting?.start_date ||
              !userSetting?.end_date ||
              !userSetting?.spreadsheet_url ||
              !timeFrame ||
              !pcStock
            }
            className="mr-4 bg-primaryDark"
            onClick={getStockPrice}
            loading={stockDetailsLoader || stockPriceLoader}
          >
            Get Data
          </Button>
        </Row>
        {priceSheetData && <LinkChildView />}
      </Row>
    </>
  );
};

export default StockPrice;
