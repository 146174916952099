import { Button, Collapse, message, Row, theme, Typography } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import MetricsCalculation from "@/Pages/Overview/StocksScreener/MetricsCalculation.jsx";
import useLoading from "@/hooks/useLoading.js";
import investServices from "@/services/apiServices/investServices/index.js";
import GrowthRate from "@/Pages/Overview/StocksScreener/GrowthRate.jsx";
import useUser from "@/store/useUser.js";
import { useCallback, useState } from "react";
import StockScreener from "@/Pages/Overview/StocksScreener/StockScreener.jsx";
import StockComparison from "@/Pages/Overview/StocksScreener/StockComparison.jsx";
import StockPrice from "./StockPrices";
import StockFinance from "./StockFinance";

const StocksScreener = () => {
  const { token } = theme.useToken();
  const panelStyle = {
    border: "none",
  };

  const { data: userData } = useUser();
  const [collapseKey, setCollapseKey] = useState(new Date());
  const [loadingType, setLoadingType] = useState("");

  const { executeAction: submitScreener } = useLoading(async (type) => {
    setLoadingType(type);
    try {
      if (type === "ALL" || type === "GrowthRate") {
        await investServices.submitGrowthRate();
        if (type === "ALL") setCollapseKey(new Date());
      }
      if (type === "ALL" || type === "MetricsCalculation") {
        await investServices.submitMetrics();
        if (type === "ALL") setCollapseKey(new Date());
      }
      if (type === "ALL" || type === "StockScreener") {
        await investServices.submitStockScreener();
        if (type === "ALL") setCollapseKey(new Date());
      }
      if (type === "ALL" || type === "StockComparison") {
        await investServices.submitStockComparison();
        if (type === "ALL") setCollapseKey(new Date());
      }
      message.success("Run Successful.");
    } catch (e) {
      console.log("e = ", e);
      message.error(
        typeof e?.response?.data?.message === typeof String()
          ? e?.response?.data?.message
          : "Something went wrong. Please try again in some time.",
      );
    } finally {
      setLoadingType("");
    }
  });

  const getItems = useCallback(
    (panelStyle) => [
      {
        key: "1",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Analyze all S&P 500 stocks by growth and group them into buckets
          </Typography.Text>
        ),
        children: (
          <GrowthRate
            key={collapseKey}
            submitScreener={submitScreener}
            isScreenerLoading={loadingType === "GrowthRate"}
          />
        ),
        style: panelStyle,
      },
      {
        key: "2",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Calculate cap rate for all stocks in S&P 500
          </Typography.Text>
        ),
        children: (
          <MetricsCalculation
            key={collapseKey}
            submitScreener={submitScreener}
            isScreenerLoading={loadingType === "MetricsCalculation"}
          />
        ),
        style: panelStyle,
      },
      {
        key: "3",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Stock Screener
          </Typography.Text>
        ),
        children: (
          <StockScreener
            key={collapseKey}
            submitScreener={submitScreener}
            isScreenerLoading={loadingType === "StockScreener"}
          />
        ),
        style: panelStyle,
      },
      {
        key: "4",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Stock Comparison
          </Typography.Text>
        ),
        children: (
          <StockComparison
            key={collapseKey}
            submitScreener={submitScreener}
            isScreenerLoading={loadingType === "StockComparison"}
          />
        ),
        style: panelStyle,
      },
      {
        key: "5",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Stock Prices
          </Typography.Text>
        ),
        children: <StockPrice />,
        style: panelStyle,
      },
      {
        key: "6",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Stock Financials
          </Typography.Text>
        ),
        children: <StockFinance />,
        style: panelStyle,
      },
    ],
    [collapseKey, submitScreener, loadingType],
  );

  return (
    <>
      <Row className="flex-col w-[500px]">
        <Row className="items-center mt-[20px]">
          <Button
            type="primary"
            className="mr-4 bg-primaryDark"
            onClick={() => submitScreener("ALL")}
            loading={loadingType === "ALL"}
          >
            Run All
          </Button>
          <Button
            href={userData?.data?.settings?.spreadsheet_url}
            disabled={
              !userData?.data?.settings?.spreadsheet_url ||
              !userData?.data?.settings?.spreadsheet_url?.includes(
                "https://docs.google.com/spreadsheets",
              )
            }
            target={"_blank"}
            className="mr-2"
          >
            Open File
          </Button>
        </Row>
      </Row>
      <Collapse
        className="mt-4"
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: token.colorBgContainer,
        }}
        items={getItems(panelStyle)}
        key={collapseKey}
      />
    </>
  );
};

export default StocksScreener;
