import { Button, DatePicker, Input, message, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import useLoading from "@/hooks/useLoading.js";
import dayjs from "dayjs";
import useUser from "@/store/useUser.js";
import userService from "@/services/apiServices/userService/index.js";

const Settings = () => {
  const [userSetting, setUserSetting] = useState({
    start_date: null,
    end_date: null,
    spreadsheet_url: "",
  });

  const { data: userData, mutate: mutateUserData } = useUser();

  useEffect(() => {
    if (userData?.data?.settings) {
      setUserSetting({
        ...userData?.data?.settings,
        start_date: dayjs(userData?.data?.settings?.start_date),
        end_date: dayjs(userData?.data?.settings?.end_date),
      });
    }
  }, [userData]);

  const { executeAction: submitUserSetting, loading: isUserSettingLoading } =
    useLoading(async () => {
      if (
        !userSetting?.start_date ||
        !userSetting?.end_date ||
        !userSetting?.spreadsheet_url
      ) {
        message.error("Please fill the details");
        return;
      }
      try {
        const payload = {
          settings: {
            start_date: dayjs(userSetting.start_date).format("YYYY-MM-DD"),
            end_date: dayjs(userSetting.end_date).format("YYYY-MM-DD"),
            spreadsheet_url: userSetting.spreadsheet_url,
          },
        };
        await userService.submitUserDetails(payload);
        await mutateUserData?.();
        message.success("Submit Successful.");
      } catch (e) {
        message.error(
          typeof e?.response?.data?.message === typeof String()
            ? e?.response?.data?.message
            : "Something went wrong. Please try again in some time.",
        );
      }
    });

  return (
    <>
      <Row className="flex-col w-[500px]">
        <Row className="items-center mt-[10px] justify-between">
          <Typography.Text className="text-[#696969]">
            <span className="text-red-700">*</span>Start Date
          </Typography.Text>
          <DatePicker
            className="w-[256px]"
            value={userSetting.start_date}
            onChange={(value) =>
              setUserSetting({ ...userSetting, start_date: value })
            }
          />
        </Row>
        <Row className="items-center mt-[20px] justify-between">
          <Typography.Text className="text-[#696969]">
            <span className="text-red-700">*</span>End Date
          </Typography.Text>
          <DatePicker
            className="w-[256px]"
            value={userSetting.end_date}
            onChange={(value) =>
              setUserSetting({ ...userSetting, end_date: value })
            }
          />
        </Row>
        <Row className="items-center mt-[20px] justify-between">
          <Typography.Text className="text-[#696969]">
            <span className="text-red-700">*</span>Google Spreadsheet URL
          </Typography.Text>
          <Input
            placeholder="https://docs.google.com/spreadsheets/"
            className="w-[256px]"
            value={userSetting.spreadsheet_url}
            onChange={(e) =>
              setUserSetting({
                ...userSetting,
                spreadsheet_url: e.target.value,
              })
            }
          />
        </Row>
        <Row className="items-center mt-[20px]">
          <Button
            type="primary"
            className="mr-4 bg-primaryDark"
            onClick={submitUserSetting}
            loading={isUserSettingLoading}
          >
            Save
          </Button>
          <Button
            href={userSetting.spreadsheet_url}
            disabled={
              !userSetting.spreadsheet_url ||
              !userSetting?.spreadsheet_url?.includes(
                "https://docs.google.com/spreadsheets",
              )
            }
            target={"_blank"}
            className="mr-2"
          >
            Open File
          </Button>
          <Button
            onClick={() => {
              setUserSetting({
                start_date: null,
                end_date: null,
                spreadsheet_url: "",
              });
            }}
          >
            Reset
          </Button>
        </Row>
      </Row>
    </>
  );
};

export default Settings;
