import { Collapse, theme, Typography } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import MyFiles from "@/Pages/Overview/MyPortfolio/MyFiles/index.jsx";
import PerformanceAnalysis from "@/Pages/Overview/MyPortfolio/PerformanceAnalysis/index.jsx";

const MyPortfolio = () => {
  const { token } = theme.useToken();
  const panelStyle = {
    border: "none",
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <Typography.Text className="text-[16px] leading-[22px] font-medium">
          My files
        </Typography.Text>
      ),
      children: <MyFiles />,
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <Typography.Text className="text-[16px] leading-[22px] font-medium">
          Performance analysis
        </Typography.Text>
      ),
      children: <PerformanceAnalysis />,
      style: panelStyle,
    },
  ];

  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: token.colorBgContainer,
        }}
        items={getItems(panelStyle)}
      />
    </>
  );
};

export default MyPortfolio;
