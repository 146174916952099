import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import { SWRConfig } from "swr";
import appRouter from "@/router/index.jsx";

const swrConfig = {
  shouldRetryOnError: false,
  revalidateOnFocus: false,
  // dedupingInterval: 900000,
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <SWRConfig value={swrConfig}>
      <RouterProvider router={appRouter} />
    </SWRConfig>
  </React.StrictMode>,
);
