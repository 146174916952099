/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Collapse,
  Input,
  Row,
  Skeleton,
  Typography,
  message,
  theme,
} from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import CreatePortfolio from "./CreatePortfolio";
import useLoading from "@/hooks/useLoading";
import investServices from "@/services/apiServices/investServices";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Modal } from "antd";

export const CustomPortfolio = () => {
  const [portfolioList, setPortfolioList] = useState([]);
  const [portfolioName, setPortfolioName] = useState("");
  const [visible, setVisible] = useState(false);
  const { token } = theme.useToken();
  const panelStyle = {
    border: "none",
  };

  const { executeAction: getPortfolioList, loading: listLoader } = useLoading(
    async () => {
      const data = await investServices.getCustomPortfolioList();
      if (data?.data?.data) {
        setPortfolioList(data?.data?.data);
      } else {
        setPortfolioList([]);
      }
    },
  );

  const { executeAction: deletePortfolio, loading: deletePortfolioLoader } =
    useLoading(async (portfolio_id) => {
      const data = await investServices.deletePortfolio({ portfolio_id });
      getPortfolioList();
      if (data?.data?.success == true) {
        message.success(data?.data?.message);
      } else {
        message.error(data?.data?.message);
      }
    });

  const {
    executeAction: createCustomPortfolio,
    loading: customPortfolioLoader,
  } = useLoading(async () => {
    let portfolio_id = 1;
    if (portfolioList?.length > 0) {
      portfolio_id =
        portfolioList?.[portfolioList?.length - 1]?.portfolio_id + 1;
    }
    const data = await investServices.updatePortfolioBalance({
      amount: 0,
      operation: "credit",
      portfolio_name: portfolioName,
      portfolio_id: portfolio_id,
      date: dayjs().format("YYYY-MM-DD"),
    });
    if (data?.data?.success == true) {
      getPortfolioList();
      setPortfolioName("");
      // message.success(data?.data?.message);
    } else {
      message.error(data?.data?.message);
    }
  });
  const onDelete = () => {
    deletePortfolio(visible);
    setVisible(false);
  };
  const onCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    getPortfolioList();
  }, []);

  const getItems = (panelStyle) =>
    portfolioList?.map((portfolio) => ({
      key: portfolio.portfolio_id,
      label: (
        <Row className="flex items-center">
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            {portfolio?.portfolio_name || `Portfolio ${portfolio.portfolio_id}`}
          </Typography.Text>
          <Button
            type="primary"
            className="ml-4 bg-primary-red"
            onClick={() => setVisible(portfolio.portfolio_id)}
            loading={deletePortfolioLoader}
          >
            Delete Portfolio
          </Button>
        </Row>
      ),
      children: <CreatePortfolio portfolio_id={portfolio.portfolio_id} />,
      style: panelStyle,
    }));

  return (
    <>
      <Row className="flex-col">
        <Row className="items-center mt-[20px] mb-[20px]">
          <Input
            placeholder="Portfolio Name"
            className="w-[256px]"
            value={portfolioName}
            onChange={(e) => setPortfolioName(e.target.value)}
          />
          <Button
            loading={customPortfolioLoader}
            onClick={() => {
              createCustomPortfolio();
            }}
            disabled={portfolioName.length < 3}
            type="primary"
            className="ml-4 bg-primaryDark"
          >
            Create Portfolio
          </Button>
        </Row>
        {listLoader ? (
          <Skeleton key={1} paragraph={{ rows: 0 }} />
        ) : (
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined className="mt-2" rotate={isActive ? 90 : 0} />
            )}
            style={{
              background: token.colorBgContainer,
            }}
            items={getItems(panelStyle)}
          />
        )}
        <Modal
          title="Confirmation"
          visible={visible}
          onOk={onDelete}
          onCancel={onCancel}
          okText="Yes"
          cancelText="No"
        >
          <p>Are you sure you want to delete Portfolio?</p>
        </Modal>
      </Row>
    </>
  );
};
