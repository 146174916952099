/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Collapse,
  message,
  Row,
  Skeleton,
  theme,
  Typography,
} from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import AnalysisByVOO from "@/Pages/Overview/MyPortfolio/PerformanceAnalysis/AnalysisByVOO.jsx";
import StockPerformanceGroup from "@/Pages/Overview/MyPortfolio/PerformanceAnalysis/StockPerformanceGroup.jsx";
import PortfolioPerformance from "@/Pages/Overview/MyPortfolio/PerformanceAnalysis/PortfolioPerformance.jsx";
import { useCallback, useEffect, useState } from "react";
import useLoading from "@/hooks/useLoading.js";
import investServices from "@/services/apiServices/investServices/index.js";
import useUser from "@/store/useUser.js";
import PerformanceLinkList from "./PerformanceLinkList";

const PerformanceAnalysis = () => {
  const { token } = theme.useToken();
  const panelStyle = {
    border: "none",
  };

  const { data: userData } = useUser();
  const [collapseKey, setCollapseKey] = useState(new Date());

  const [graphData, setGraphData] = useState({
    tradeData: [],
    stockLinks: [],
    portfolioData: [],
    previewDate: "",
    portfolioFilterData: [],
    portfolioStockGroupData: [],
    portfolioData_spreadsheet_links: [],
    portfolioFilterData_spreadsheet_links: [],
    portfolioStockGroupData_spreadsheet_links: [],
  });

  const LinkChildView = ({ name, value }) => (
    <div key={name}>
      <strong>{name} : </strong>{" "}
      <a target="_blank" href={value} rel="noreferrer">
        {value}
      </a>
    </div>
  );

  const [loadingType, setLoadingType] = useState("");

  const { executeAction: submitPortfolio, loading: isPortfolioLoading } =
    useLoading(async (type, params = {}) => {
      setLoadingType(type);
      try {
        if (type === "ALL") {
          const dataLinks = await investServices.getMonthlyStockLinks(
            `start_date=${userData?.data?.settings?.start_date}&end_date=${userData?.data?.settings?.end_date}`,
          );
          setGraphData((prevData) => ({
            ...prevData,
            stockLinks: dataLinks?.data?.data,
          }));
        }
        if (type === "ALL" || type === "AnalysisByVOO") {
          const data1 = await investServices.submitCompareTrades({
            start_date: userData?.data?.settings?.start_date,
            end_date: userData?.data?.settings?.end_date,
          });
          if (type === "ALL") setCollapseKey(new Date());
          setGraphData((prevData) => ({
            ...prevData,
            tradeData: data1?.data?.graph_data,
          }));
        }

        if (type === "ALL" || type === "PortfolioPerformance") {
          const data2 = await investServices.submitPortfolioPerformance({
            start_date:
              params?.start_date || userData?.data?.settings?.start_date,
            end_date: params?.end_date || userData?.data?.settings?.end_date,
            is_history: false,
          });
          if (type === "ALL") setCollapseKey(new Date());
          setGraphData((prevData) => ({
            ...prevData,
            previewDate: "",
            portfolioData: data2?.data?.data,
            portfolioData_spreadsheet_links: data2?.data?.spreadsheet_links,
          }));
        }

        if (type === "ALL" || type === "PortfolioPerformance") {
          const data2 = await investServices.getFilterPortfolioPerformance({
            params: {
              start_date:
                params?.start_date || userData?.data?.settings?.start_date,
              end_date: params?.end_date || userData?.data?.settings?.end_date,
            },
          });
          if (type === "ALL") setCollapseKey(new Date());
          setGraphData((prevData) => ({
            ...prevData,
            portfolioFilterData: data2?.data?.data,
            portfolioFilterData_spreadsheet_links:
              data2?.data?.spreadsheet_links,
          }));
        }

        if (type === "ALL" || type === "PortfolioPerformance") {
          const data3 = await investServices.submitStockGroup({
            start_date:
              params?.start_date || userData?.data?.settings?.start_date,
            end_date: params?.end_date || userData?.data?.settings?.end_date,
            is_history: false,
          });
          const dataSet = [
            {
              name: "Index Group",
              key: "index_group_data",
              value: data3?.data?.index_group_data,
            },
            {
              name: "KS Group",
              key: "ks_group_data",
              value: data3?.data?.ks_group_data,
            },
          ];
          setGraphData((prevData) => ({
            ...prevData,
            portfolioStockGroupData: dataSet,
            portfolioStockGroupData_spreadsheet_links:
              data3?.data?.spreadsheet_links,
          }));
          if (type === "ALL") setCollapseKey(new Date());
        }
        message.success("Run Successful.");
      } catch (e) {
        message.error(
          typeof e?.response?.data?.message === typeof String()
            ? e?.response?.data?.message
            : "Something went wrong. Please try again in some time.",
        );
      } finally {
        setLoadingType("");
      }
    });

  useEffect(() => {
    silentCallHistoryData();
    getLinksData();
  }, []);
  const { executeAction: getLinksData, loading: isLinkLoading } = useLoading(
    async () => {
      const dataLinks = await investServices.getMonthlyStockLinks(
        `start_date=${userData?.data?.settings?.start_date}&end_date=${userData?.data?.settings?.end_date}`,
      );
      setGraphData((prevData) => ({
        ...prevData,
        stockLinks: dataLinks?.data?.data,
      }));
    },
  );

  const silentCallHistoryData = () => {
    investServices
      .submitPortfolioPerformance({
        is_history: true,
      })
      .then((response) => {
        if (response?.data?.data) {
          setGraphData((prevData) => ({
            ...prevData,
            portfolioData: response?.data?.data,
            portfolioData_spreadsheet_links: response?.data?.spreadsheet_links,
            previewDate: response?.data?.date,
          }));
        }
      });

    investServices
      .getFilterPortfolioPerformance({
        params: {
          is_history: true,
        },
      })
      .then((response) => {
        if (response?.data?.data) {
          setGraphData((prevData) => ({
            ...prevData,
            portfolioFilterData_spreadsheet_links:
              response?.data?.spreadsheet_links,
            portfolioFilterData: response?.data?.data,
          }));
        }
      });

    investServices
      .submitStockGroup({
        is_history: true,
      })
      .then((response) => {
        if (response?.data?.index_group_data && response?.data?.ks_group_data) {
          const dataSet = [
            {
              name: "Index Group",
              key: "index_group_data",
              value: response?.data?.index_group_data,
            },
            {
              name: "KS Group",
              key: "ks_group_data",
              value: response?.data?.ks_group_data,
            },
          ];
          setGraphData((prevData) => ({
            ...prevData,
            portfolioStockGroupData: dataSet,
            portfolioStockGroupData_spreadsheet_links:
              response?.data?.spreadsheet_links,
          }));
        }
      });
  };
  const getItems = useCallback(
    (panelStyle) => [
      {
        key: "1",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Compare Performance of Every Trade in Portfolio
          </Typography.Text>
        ),
        children: (
          <AnalysisByVOO
            key={collapseKey}
            graphData={graphData.tradeData}
            submitPortfolio={submitPortfolio}
            isPortfolioLoading={loadingType === "AnalysisByVOO"}
          />
        ),
        style: panelStyle,
      },
      {
        key: "2",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Monthly Portfolio Performance (Percent Change vs. Cumulative Percent
            Change)
          </Typography.Text>
        ),
        children: (
          <PortfolioPerformance
            key={collapseKey}
            wholeGraphData={graphData}
            submitPortfolio={submitPortfolio}
            isPortfolioLoading={loadingType === "PortfolioPerformance"}
          />
        ),
        style: panelStyle,
      },
      {
        key: "3",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Compare stock performance by stock group
          </Typography.Text>
        ),
        children: (
          <StockPerformanceGroup
            key={collapseKey}
            submitPortfolio={submitPortfolio}
            isPortfolioLoading={loadingType === "StockPerformanceGroup"}
          />
        ),
        style: panelStyle,
      },
      {
        key: "4",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Spreadsheet Links
          </Typography.Text>
        ),
        children: <PerformanceLinkList />,
        style: panelStyle,
      },
    ],
    [graphData, collapseKey, submitPortfolio, isPortfolioLoading, loadingType],
  );

  return (
    <>
      <Row className="flex-col w-[500px]">
        <Row className="items-center mt-[20px]">
          <Button
            type="primary"
            className="mr-4 bg-primaryDark"
            onClick={() => submitPortfolio("ALL")}
            loading={loadingType === "ALL"}
          >
            Run All
          </Button>
          <Button
            href={userData?.data?.settings?.spreadsheet_url}
            disabled={
              !userData?.data?.settings?.spreadsheet_url ||
              !userData?.data?.settings?.spreadsheet_url?.includes(
                "https://docs.google.com/spreadsheets",
              )
            }
            target={"_blank"}
            className=" mr-2"
          >
            Open File
          </Button>
        </Row>
      </Row>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: token.colorBgContainer,
        }}
        className="mt-4"
        key={collapseKey}
        items={getItems(panelStyle)}
      />

      <Row className="pl-[20px]">
        {isLinkLoading ? (
          <Skeleton key={1} paragraph={{ rows: 0 }} />
        ) : (
          graphData?.stockLinks?.map((item) => {
            return Object.entries(item).map(([key, value]) => (
              <LinkChildView key={key} name={key} value={value} />
            ));
          })
        )}
      </Row>
    </>
  );
};

export default PerformanceAnalysis;
