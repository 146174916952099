import { useEffect } from "react";
import { Col } from "antd";
import { getAccessToken } from "@/utils/index.js";
import { Outlet, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CLIENT_ID } from "@/constants/index.js";
import useUser from "@/store/useUser.js";

function App() {
  const accessToken = getAccessToken();
  const navigate = useNavigate();

  useUser();

  useEffect(() => {
    if (!accessToken || accessToken?.length === 0) {
      navigate("/login");
    } else navigate("/overview");
  }, [accessToken, navigate]);

  return (
    <Col className="h-screen w-full">
      <GoogleOAuthProvider
        clientId={
          import.meta.env.VITE_ACTIVE_ENV === "PRODUCTION"
            ? CLIENT_ID.PRODUCTION
            : CLIENT_ID.STAGING
        }
      >
        <Outlet />
      </GoogleOAuthProvider>
    </Col>
  );
}

export default App;
