import apiClient from "@/services/apiClient/index.js";

export const urls = {
  growthRate: `/api/screener/growth_rate`,
  getTransactionFiles: `/api/files/get/all_transaction_files`,
  getPositionFiles: `/api/files/get/all_position_files`,
  getFileLinks: `/api/files/get/get_all_files`,
  getPerformanceFileLinks: `/api/performance_analysis/get_stock_group_performance`,
  compareTrades: `/api/performance_analysis/compare_trades`,
  monthlyStockLinks: `/api/performance_analysis/monthly_stock_price`,
  stockGroup: `/api/performance_analysis/stock_group_performance`,
  metrics: `/api/screener/metrics_calculation`,
  monthARR: `/api/performance_analysis/month_over_month_arr`,
  portfolioPerformance: `/api/performance_analysis/portfolio_performance`,
  filterPortfolioPerformance: `/api/performance_analysis/portfolio_performance_filtered`,
  // stock_group_performance: `/api/performance_analysis/stock_group_performance`,
  stockScreener: `/api/screener/stock_screener`,
  stockComparison: `/api/screener/generate_stock_comparison`,
  getPortfolioBalance: `/api/performance_analysis/get_user_balance`,
  getStockList: `/api/performance_analysis/get_stocks_data`,
  getPortfolioLinks: `/api/performance_analysis/get_portfolio`,
  updatePortfolioBalance: `/api/performance_analysis/operate_balance`,
  buySellStock: `/api/performance_analysis/trade_stock`,
  getCustomPortfolioList: `/api/performance_analysis/all_portfolios`,
  getStockPriceByDate: `/api/performance_analysis/get_stock_price`,
  deletePortfolio: `/api/performance_analysis/delete_portfolio`,
  getStockPrice: `/api/screener/stock_price`,
  getStockFinancial: `/api/screener/financials_data`,
};

export default {
  submitGrowthRate: () => apiClient.post(urls.growthRate),
  getHistory: () => apiClient.get(urls.growthRate),
  submitCompareTrades: (payload) => apiClient.post(urls.compareTrades, payload),
  getCompareTrades: () => apiClient.get(urls.compareTrades),
  getMonthlyStockLinks: (params) =>
    apiClient.get(`${urls.monthlyStockLinks}?${params}`),
  getTransactionFiles: () => apiClient.get(urls.getTransactionFiles),
  getPositionFiles: () => apiClient.get(urls.getPositionFiles),
  getFileLinks: (spreadsheet_url) =>
    apiClient.get(`${urls.getFileLinks}?spreadsheet_url=${spreadsheet_url}`),
  getPerformanceFileLinks: (payload) =>
    apiClient.post(urls.getPerformanceFileLinks, payload),
  getStockGroup: () => apiClient.get(urls.stockGroup),
  submitStockGroup: (payload) => apiClient.post(urls.stockGroup, payload),
  getMetrics: () => apiClient.get(urls.metrics),
  submitMetrics: () => apiClient.post(urls.metrics),
  getMonthARR: () => apiClient.get(urls.monthARR),
  submitMonthARR: () => apiClient.post(urls.monthARR),
  getPortfolioPerformance: () => apiClient.get(urls.portfolioPerformance),
  getFilterPortfolioPerformance: (payload) =>
    apiClient.get(urls.filterPortfolioPerformance, payload),
  submitPortfolioPerformance: (payload) =>
    apiClient.post(urls.portfolioPerformance, payload),
  getStockScreener: () => apiClient.get(urls.stockScreener),
  submitStockScreener: () => apiClient.post(urls.stockScreener),
  getStockComparison: () => apiClient.get(urls.stockComparison),
  submitStockComparison: () => apiClient.post(urls.stockComparison),
  getPortfolioBalance: (portfolio_id) =>
    apiClient.get(`${urls.getPortfolioBalance}?portfolio_id=${portfolio_id}`),
  getStockList: (portfolio_id) =>
    apiClient.get(`${urls.getStockList}?portfolio_id=${portfolio_id}`),
  getAllStockList: () => apiClient.get(`${urls.getStockList}?portfolio_id=0`),
  getPortfolioLinks: (portfolio_id) =>
    apiClient.get(`${urls.getPortfolioLinks}?portfolio_id=${portfolio_id}`),
  updatePortfolioBalance: (payload) =>
    apiClient.post(urls.updatePortfolioBalance, payload),
  buySellStock: (payload) => apiClient.post(urls.buySellStock, payload),
  getCustomPortfolioList: () => apiClient.get(urls.getCustomPortfolioList),
  getStockPriceByDate: (params) =>
    apiClient.get(`${urls.getStockPriceByDate}?${params}`),
  deletePortfolio: (payload) => apiClient.post(urls.deletePortfolio, payload),
  getStockPrice: (params) => apiClient.get(`${urls.getStockPrice}?${params}`),
  getStockFinancial: (params) =>
    apiClient.get(`${urls.getStockFinancial}?${params}`),
};
