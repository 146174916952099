import { Button, DatePicker, Row, Typography } from "antd";
import CustomTable from "@/components/CustomTable/index.jsx";
import UploadPosition from "@/components/UploadPosition/index.jsx";
import { useState } from "react";
import dayjs from "dayjs";
import usePositions from "@/store/usePositions.js";
import environment from "@/environment/index.js";

const PositionFiles = () => {
  const {
    data: positionData,
    mutate: mutatePositionData,
    isLoading,
  } = usePositions();
  const [positionDate, setPositionDate] = useState();

  const fileHeaders = [
    {
      title: "Date of upload",
      dataIndex: "uploaded_on",
    },
    {
      title: "Position Date",
      dataIndex: "positions_date",
    },
    {
      title: "View",
      dataIndex: "file_path",
      render: (value) => (
        <Button
          type="link"
          href={`${environment.BASE_URL}static/${value}`}
          target={"_blank"}
          className="p-0 m-0"
        >
          Open file
        </Button>
      ),
    },
  ];

  return (
    <>
      <Row className="items-center w-[400px] mb-[10px] w-[500px] justify-between">
        <Typography.Text className="text-[#696969]">
          <span className="text-red-700">*</span>Position Date
        </Typography.Text>
        <DatePicker
          className="w-[256px]"
          value={positionDate}
          onChange={(value) => setPositionDate(value)}
        />
      </Row>
      <div className="mb-[20px]">
        <UploadPosition
          positionDate={
            positionDate ? dayjs(positionDate).format("YYYY-MM-DD") : null
          }
          onSuccess={mutatePositionData}
        />
      </div>
      <CustomTable
        isTableLoading={isLoading}
        tableHeaders={fileHeaders}
        tableData={positionData?.data?.data || []}
      />
    </>
  );
};

export default PositionFiles;
