/* eslint-disable react-hooks/exhaustive-deps */
import useLoading from "@/hooks/useLoading";
import investServices from "@/services/apiServices/investServices";
import { CaretRightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Collapse,
  DatePicker,
  Input,
  Row,
  Select,
  Skeleton,
  Typography,
  message,
  theme,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { PerformanceAnalysis } from "./PerformanceAnalysis";

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};

const CreatePortfolio = ({ portfolio_id }) => {
  const { token } = theme.useToken();
  const [stockList, setStockList] = useState([]);
  const [pcStock, setPcStock] = useState("");
  const [price, setPrice] = useState("");
  const [customStock, setCustomStock] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [quantity, setQuantity] = useState("");
  const [linkList, setLinkList] = useState([]);
  const [collapseKey, setCollapseKey] = useState(new Date());
  const panelStyle = {
    border: "none",
  };
  const [balance, setBalance] = useState(0);

  const [addAmount, setAddAmount] = useState("");
  const debouncedSearchTerm = useDebouncedValue(customStock, 1000);

  useEffect(() => {
    if (pcStock) {
      console.log("quantity = ", stockList?.[pcStock]);
      setSelectedDate(dayjs(new Date()));
      setQuantity(stockList?.[pcStock]?.quantity);
    }
  }, [pcStock, stockList]);
  useEffect(() => {
    const stockName =
      pcStock == "custom" ? customStock : stockList?.[pcStock]?.label;
    if (!!stockName && dayjs(selectedDate).isValid) {
      getStockPriceByDate({
        stock: stockName,
        date: dayjs(selectedDate).format("YYYY-MM-DD"),
      });
    }
  }, [selectedDate, pcStock, debouncedSearchTerm]);

  const { executeAction: getBalance, loading: balanceLoader } = useLoading(
    async () => {
      const data = await investServices.getPortfolioBalance(portfolio_id);
      if (data?.data?.balance) {
        setBalance(data?.data?.balance);
      } else {
        setBalance(0);
      }
    },
  );

  useEffect(() => {
    if (customStock == "") {
      setPrice(0);
    }
  }, [customStock]);

  const { executeAction: getStockPriceByDate, loading: stockPriceLoader } =
    useLoading(async ({ stock, date }) => {
      const params = `stock=${stock}&date=${date}`;
      const data = await investServices.getStockPriceByDate(params);
      if (data?.data?.price) {
        setPrice(data?.data?.price);
      } else {
        setPrice(0);
      }
    });

  const { executeAction: getStockDetails, loading: stockDetailsLoader } =
    useLoading(async () => {
      const stockData = await investServices.getStockList(portfolio_id);
      if (stockData?.data?.data) {
        let dataArr = [
          {
            value: "",
            label: "Select Stock",
          },
        ];

        stockData?.data?.data?.map((item, index) => {
          dataArr.push({
            value: index + 1,
            label: item?.stock,
            ...item,
          });
        });
        dataArr.push({
          value: "custom",
          label: "Type your Stock",
        });

        setStockList(dataArr);
      }
      const data = await investServices.getPortfolioLinks(portfolio_id);
      if (data?.data?.data) {
        setLinkList(data?.data?.data);
      }
    });

  const { executeAction: updateBalance, loading: updateBalanceLoader } =
    useLoading(async (params) => {
      const data = await investServices.updatePortfolioBalance({
        amount: parseFloat(params?.amount),
        operation: params?.operation,
        date: dayjs().format("YYYY-MM-DD"),
        portfolio_id,
      });
      setCollapseKey(new Date());
      if (data?.data?.success == true) {
        setBalance(data?.data?.balance || 0);
        setAddAmount("");
        message.success(data?.data?.message);
      } else {
        message.error(data?.data?.message);
      }
    });

  const { executeAction: buySellStock, loading: buySellStockLoading } =
    useLoading(async (params) => {
      const data = await investServices.buySellStock({
        stock: params?.stock,
        price: parseFloat(params?.price),
        quantity: parseInt(params?.quantity),
        trade: params?.trade,
        date: dayjs(params?.selectedDate).format("YYYY-MM-DD"),
        portfolio_id,
      });
      getBalance();
      getStockDetails();
      if (data?.data?.success == true) {
        message.success(data?.data?.message);
        setPcStock("");
        setPrice("");
        setCustomStock("");
        setSelectedDate("");
        setQuantity("");
      } else {
        message.error(data?.data?.message);
      }
    });

  useEffect(() => {
    getBalance();
    getStockDetails();
  }, []);

  const onAddWithdrawBalance = (operation) => {
    if (addAmount && addAmount > 0) {
      updateBalance({ amount: addAmount, operation });
    }
  };

  const onBuySellStock = (trade) => {
    const params = {
      stock: pcStock == "custom" ? customStock : stockList?.[pcStock]?.label,
      price: price,
      quantity: quantity,
      selectedDate: selectedDate,
      trade,
    };
    buySellStock(params);
  };

  const LinkChildView = ({ name, value }) => (
    <div key={name}>
      <strong>{name} : </strong>{" "}
      <a target="_blank" href={value} rel="noreferrer">
        {value}
      </a>
    </div>
  );

  const getItems = useCallback(
    (panelStyle) => [
      {
        key: "1",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Buy Sell Stock
          </Typography.Text>
        ),
        children: (
          <Row className="pl-[20px] flex flex-col">
            <Row className="items-center mt-[10px] justify-between  w-[500px]">
              <Typography.Text className="text-[#696969]">
                <span className="text-red-700">*</span>Stock Group
              </Typography.Text>

              {pcStock != "custom" ? (
                <Select
                  value={pcStock}
                  placeholder={"Select Stock"}
                  onChange={setPcStock}
                  className="w-[256px]"
                  options={stockList}
                />
              ) : (
                <Input
                  placeholder="Stock Name"
                  addonAfter={
                    <CloseCircleOutlined
                      onClick={() => {
                        setCustomStock("");
                        setPcStock("");
                      }}
                    />
                  }
                  className="w-[256px]"
                  value={customStock}
                  onChange={(e) => setCustomStock(e.target.value)}
                />
              )}
            </Row>
            <Row className="items-center mt-[20px] justify-between w-[500px]">
              <Typography.Text className="text-[#696969]">
                <span className="text-red-700">*</span>Date
              </Typography.Text>
              <DatePicker
                className="w-[256px]"
                maxDate={dayjs()}
                value={selectedDate}
                onChange={setSelectedDate}
              />
            </Row>
            <Row className="items-center mt-[20px] justify-between w-[500px]">
              <Typography.Text className="text-[#696969]">
                <span className="text-red-700">*</span>Price
              </Typography.Text>
              {stockPriceLoader ? (
                <Skeleton
                  key={1}
                  className="w-[256px]"
                  paragraph={{ rows: 0 }}
                />
              ) : (
                <Input
                  placeholder="Price"
                  className="w-[256px]"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              )}
            </Row>
            <Row className="items-center mt-[20px] justify-between w-[500px]">
              <Typography.Text className="text-[#696969]">
                <span className="text-red-700">*</span>Quantity
              </Typography.Text>
              <Input
                placeholder="Quantity"
                className="w-[256px]"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </Row>
            <Row className="items-center mt-[20px] mb-[20px]">
              <Button
                type="primary"
                className="mr-4 bg-primaryDark"
                onClick={() => onBuySellStock("buy")}
                disabled={
                  !pcStock || !price || price < 1 || !quantity || quantity < 1
                }
                loading={buySellStockLoading}
              >
                Buy Stock
              </Button>
              <Button
                type="primary"
                className="mr-4 bg-primaryDark"
                onClick={() => onBuySellStock("sell")}
                disabled={
                  !pcStock || !price || price < 1 || !quantity || quantity < 1
                }
                loading={buySellStockLoading}
              >
                Sell Stock
              </Button>
            </Row>
          </Row>
        ),
        style: panelStyle,
      },
      {
        key: "3",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Spreadsheet Links
          </Typography.Text>
        ),
        children: (
          <Row className="pl-[20px] flex flex-col">
            {stockDetailsLoader ? (
              <Skeleton key={1} paragraph={{ rows: 0 }} />
            ) : (
              linkList?.map((item, index) => (
                <div className="mb-5" key={index}>
                  {Object.entries(item).map(([key, value]) => (
                    <LinkChildView key={key} name={key} value={value} />
                  ))}
                </div>
              ))
            )}
          </Row>
        ),
        style: panelStyle,
      },
      {
        key: "4",
        label: (
          <Typography.Text className="text-[16px] leading-[22px] font-medium">
            Performance Analysis
          </Typography.Text>
        ),
        children: <PerformanceAnalysis portfolio_id={portfolio_id} />,
        style: panelStyle,
      },
    ],
    [
      buySellStockLoading,
      linkList,
      onBuySellStock,
      pcStock,
      price,
      quantity,
      stockDetailsLoader,
      stockList,
      portfolio_id,
    ],
  );

  return (
    <Row className="pl-[20px] flex flex-col">
      {balanceLoader ? (
        <Skeleton key={1} paragraph={{ rows: 0 }} />
      ) : (
        <Row className="flex-col w-[500px]">
          <Row className="items-center mt-[20px] justify-start">
            <Typography.Text className="text-[#000] w-[180px]">
              Balance :
            </Typography.Text>
            <Typography.Text className="text-[#000] font-bold">
              ${parseFloat(balance).toFixed(2)}
            </Typography.Text>
          </Row>
          <Row className="items-center mt-[20px] justify-start">
            <Typography.Text className="text-[#000] w-[180px]">
              Amount
            </Typography.Text>
            <Input
              placeholder="Amount"
              className="w-[320px]"
              value={addAmount}
              onChange={(e) => setAddAmount(e.target.value)}
            />
          </Row>
          <Row className="items-center mt-[20px] justify-center">
            <Button
              loading={updateBalanceLoader}
              disabled={!addAmount | (addAmount < 1)}
              type="primary"
              onClick={() => {
                onAddWithdrawBalance("credit");
              }}
              className="ml-12 bg-primaryDark"
            >
              Add Amount
            </Button>
            <Button
              loading={updateBalanceLoader}
              type="primary"
              disabled={!addAmount | (addAmount < 1)}
              onClick={() => {
                onAddWithdrawBalance("debit");
              }}
              className="ml-10 bg-primaryDark"
            >
              Withdraw Amount
            </Button>
          </Row>
        </Row>
      )}
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: token.colorBgContainer,
        }}
        className="mt-4"
        key={collapseKey}
        items={getItems(panelStyle)}
      />
    </Row>
  );
};

export default CreatePortfolio;
