import { Empty } from "antd";
import { DualAxes, Line } from "@ant-design/plots";

const ChartCard = ({ chartType, chartConfig }) => {
  if (!chartConfig || !chartConfig?.data || !chartConfig?.data?.length)
    return <Empty />;

  return (
    <>
      {chartType === "LINE" && <Line {...chartConfig} />}
      {chartType === "DUAL_AXES" && <DualAxes {...chartConfig} />}
    </>
  );
};

export default ChartCard;
