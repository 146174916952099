import { useState } from "react";
import { Table } from "antd";
import SkeletonTable from "@/components/Skeletons/SkeletonTable.jsx";

const CustomTable = ({
  tableData,
  isTableLoading,
  tableHeaders,
  onRowClick,
}) => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const handlePaginationChange = (page, pageSize) => {
    setPagination({
      pageNumber: page,
      pageSize,
    });
  };

  if (isTableLoading) return <SkeletonTable columns={tableHeaders} />;

  return (
    <Table
      rowKey="id"
      rowClassName="cursor-pointer"
      columns={tableHeaders || []}
      dataSource={tableData || []}
      onRow={(record) => {
        return {
          onClick: () => onRowClick?.(record), // click row
        };
      }}
      // scroll={{ x: "max-content" }}
      pagination={{
        size: "small",
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: [5, 10, 50],
        current: pagination.pageNumber,
        pageSize: pagination.pageSize,
        onChange: handlePaginationChange,
        showTotal: (total) => `Total ${total} Items`,
        total: tableData?.length || 0,
      }}
    />
  );
};

export default CustomTable;
