import { Button, Collapse, Row, theme, Typography, DatePicker } from "antd";
import {
  CaretRightOutlined,
  EyeFilled,
  EyeInvisibleFilled,
} from "@ant-design/icons";
import ChartCard from "@/components/ChartCard/index.jsx";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

const StockPerformanceGroup = ({
  wholeGraphData,
  submitPortfolio,
  isPortfolioLoading,
}) => {
  const [stockStartDate, setStockStartDate] = useState("");
  const [stockEndDate, setStockEndDate] = useState("");

  const [groupStockData, setGroupStockData] = useState([]);
  const [groupStockChartData, setGroupStockChartData] = useState([]);

  const dataDivRef = useRef();
  const dataAltDivRef = useRef();

  const [stockList, setStockList] = useState([]);
  const [filterStockWiseGraphDataArr, setFilterStockWiseGraphDataArr] =
    useState([]);
  const [filterStockGraphArr, setFilterStockGraphArr] = useState([]);

  const [CumulativeGraphData, setCumulativeGraphData] = useState([]);
  const [PercentGraphData, setPercentGraphData] = useState([]);

  useEffect(() => {
    if (wholeGraphData?.portfolioFilterData) {
      const stockWiseArr = Object.entries(
        wholeGraphData?.portfolioFilterData,
      ).map(([key, value]) => ({
        key,
        value,
      }));
      const graphArr = [];
      const stockSetArr = [];
      stockWiseArr?.map((item) => {
        stockSetArr.push({ value: item?.key, label: item?.key });
        item?.value?.map((itm) => {
          graphArr.push({ ...itm, type: item?.key });
        });
      });
      if (stockList?.length == 0) setStockList(stockSetArr);
      setFilterStockWiseGraphDataArr(stockWiseArr);
      setFilterStockGraphArr(graphArr);
    }
  }, [wholeGraphData?.portfolioFilterData, stockList?.length]);

  useEffect(() => {
    const CumulativeGraphDataArr = [];
    const PercentGraphDataArr = [];
    wholeGraphData?.portfolioData?.map((item) => {
      if (item?.category == "Weighted Per Change") {
        PercentGraphDataArr.push({
          ...item,
          category: "Percentage",
        });
      } else if (item?.category == "Cumulative %age change") {
        CumulativeGraphDataArr.push({
          ...item,
          category: "Cumulative Percentage",
        });
      }
    });
    setCumulativeGraphData(CumulativeGraphDataArr);
    setPercentGraphData(PercentGraphDataArr);
  }, [wholeGraphData?.portfolioData]);

  useEffect(() => {
    if (wholeGraphData?.portfolioStockGroupData?.length > 0) {
      const dataArr = [];
      wholeGraphData?.portfolioStockGroupData?.map((item) => {
        item?.value?.map((itm) => {
          dataArr.push({
            cumulative_change: itm?.cumulative_change,
            date: itm?.date,
            key: item?.key,
            name: item?.name,
            percentage_change: itm?.percentage_change,
          });
        });
      });
      setGroupStockChartData(dataArr);
      setGroupStockData(wholeGraphData?.portfolioStockGroupData);
    }
  }, [wholeGraphData?.portfolioStockGroupData]);

  const getFilterStockPrGraphData = (dataSet, type) => {
    if (type == "cumulative_change") {
      return dataSet?.filter((item) => !item?.hideCCData);
    } else if (type == "percentage_change") {
      return dataSet?.filter((item) => !item?.hidePCData);
    }
    return dataSet;
  };

  const changeFilterDisplayStatus = (index, type) => {
    const localArr = [...filterStockWiseGraphDataArr];
    const localArrGraph = [...filterStockGraphArr];
    if (index == "all") {
      if (type == "cumulative_change") {
        localArr?.map((itm, ind) => {
          localArr[ind].hideCCData = true;
        });
      } else if (type == "percentage_change") {
        localArr?.map((itm, ind) => {
          localArr[ind].hidePCData = true;
        });
      }
      localArrGraph?.map((itm, ind) => {
        if (type == "cumulative_change") {
          localArrGraph[ind].hideCCData = true;
        } else if (type == "percentage_change") {
          localArrGraph[ind].hidePCData = true;
        }
      });
    } else {
      if (type == "cumulative_change") {
        localArr[index].hideCCData = !localArr?.[index]?.hideCCData;
      } else if (type == "percentage_change") {
        localArr[index].hidePCData = !localArr?.[index]?.hidePCData;
      }

      localArrGraph?.map((itm, ind) => {
        if (itm.type == localArr[index].key) {
          if (type == "cumulative_change") {
            localArrGraph[ind].hideCCData = !localArrGraph?.[ind]?.hideCCData;
          } else if (type == "percentage_change") {
            localArrGraph[ind].hidePCData = !localArrGraph?.[ind]?.hidePCData;
          }
        }
      });
    }
    setFilterStockWiseGraphDataArr(localArr);
    setFilterStockGraphArr(localArrGraph);
  };

  const getStockGroupFilterData = (dataSet, type) => {
    if (type == "cumulative_change") {
      return dataSet?.filter((item) => !item?.hideCPData);
    } else if (type == "percentage_change") {
      return dataSet?.filter((item) => !item?.hidePCData);
    }
    return dataSet;
  };

  const changeGroupStockDisplayStatus = (index, type) => {
    const dataArr = [...groupStockData];
    const chartData = [...groupStockChartData];
    if (type == "percentage_change") {
      chartData?.map((item, ind) => {
        if (item.key == dataArr[index].key) {
          chartData[ind].hidePCData = !dataArr[index]?.hidePCData;
        }
      });
      dataArr[index].hidePCData = !dataArr[index]?.hidePCData;
    } else {
      chartData?.map((item, ind) => {
        if (item.key == dataArr[index].key) {
          chartData[ind].hideCPData = !dataArr[index]?.hideCPData;
        }
      });
      dataArr[index].hideCPData = !dataArr[index]?.hideCPData;
    }
    setGroupStockChartData(chartData);
    setGroupStockData(dataArr);
  };

  const { token } = theme.useToken();
  const panelStyle = {
    border: "none",
  };

  const lineCumulativeWholeChartConfig = {
    data: CumulativeGraphData || [],
    xField: "date",
    yField: "value",
    colorField: "category",
    legend: false,
  };
  const linePercentWholeChartConfig = {
    data: PercentGraphData || [],
    xField: "date",
    yField: "value",
    colorField: "category",
    legend: false,
  };

  const lineCumulativeGroupChartConfig = {
    data: getStockGroupFilterData(groupStockChartData, "cumulative_change"),
    xField: "date",
    yField: "cumulative_change",
    colorField: "name",
  };
  const linePercentGroupChartConfig = {
    data: getStockGroupFilterData(groupStockChartData, "percentage_change"),
    xField: "date",
    yField: "percentage_change",
    colorField: "name",
  };

  const lineCumulativePercentageChartConfig = {
    data: getFilterStockPrGraphData(filterStockGraphArr, "cumulative_change"),
    xField: "date",
    yField: "cumulative_change",
    colorField: "type",
  };

  const linePercentChangeChartConfig = {
    data: getFilterStockPrGraphData(filterStockGraphArr, "percentage_change"),
    xField: "date",
    yField: "percentage_change",
    colorField: "type",
  };

  const ListItem = ({ data, index, type }) => {
    return (
      <div className="flex justify-start items-center py-2 border-b border-gray-200">
        <Typography.Text className="text-[14px] flex-1 font-normal">
          {data?.key}
        </Typography.Text>
        {filterStockWiseGraphDataArr?.length > 1 && (
          <div
            className="p-2 mr-3"
            onClick={() => changeFilterDisplayStatus(index, type)}
          >
            {type == "cumulative_change" ? (
              data?.hideCCData ? (
                <EyeInvisibleFilled />
              ) : (
                <EyeFilled />
              )
            ) : data?.hidePCData ? (
              <EyeInvisibleFilled />
            ) : (
              <EyeFilled />
            )}
          </div>
        )}
      </div>
    );
  };

  const GroupListItem = ({ data, index, type }) => {
    return (
      <div className="flex justify-start items-center">
        <Typography.Text className="text-[14px] flex-1 font-normal">
          {data?.name}
        </Typography.Text>
        <div
          className="p-2 mr-3"
          onClick={() => changeGroupStockDisplayStatus(index, type)}
        >
          {type == "percentage_change" ? (
            data?.hidePCData ? (
              <EyeInvisibleFilled />
            ) : (
              <EyeFilled />
            )
          ) : data?.hideCPData ? (
            <EyeInvisibleFilled />
          ) : (
            <EyeFilled />
          )}
        </div>
      </div>
    );
  };

  const LinkChildView = ({ name, value }) => (
    <div key={name}>
      <strong>{name} : </strong>{" "}
      <a target="_blank" href={value} rel="noreferrer">
        {value}
      </a>
    </div>
  );

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <Typography.Text className="text-[16px] font-semibold mb-[20px]">
          Percent Change by Stock
        </Typography.Text>
      ),
      children: (
        <div>
          {filterStockWiseGraphDataArr?.length > 0 ? (
            <>
              {wholeGraphData?.portfolioFilterData_spreadsheet_links?.map(
                (item, index) => (
                  <div className="mb-5" key={index}>
                    {Object.entries(item).map(([key, value]) => (
                      <LinkChildView key={key} name={key} value={value} />
                    ))}
                  </div>
                ),
              )}
              <Button
                onClick={() => {
                  changeFilterDisplayStatus("all", "percentage_change");
                }}
              >
                Hide All
              </Button>
              <div className="w-full flex flex-row items-center	">
                <div
                  ref={dataDivRef}
                  className="w-1/5 h-[550px] overflow-y-auto"
                >
                  {filterStockWiseGraphDataArr?.map((item, ind) => (
                    <ListItem
                      key={ind}
                      type="percentage_change"
                      data={item}
                      index={ind}
                    />
                  ))}
                </div>
                <div className="w-[2px] ml-[15px] h-[550px] bg-gray-300" />
                <Row className="w-4/5 flex-col">
                  <ChartCard
                    key={linePercentChangeChartConfig}
                    chartConfig={linePercentChangeChartConfig}
                    chartType={"LINE"}
                  />
                </Row>
              </div>
            </>
          ) : (
            <Row className="w-full flex-col">
              <ChartCard
                key={linePercentChangeChartConfig}
                chartConfig={linePercentChangeChartConfig}
                chartType={"LINE"}
              />
            </Row>
          )}
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <Typography.Text className="text-[16px] font-semibold mb-[20px]">
          Cumulative Percent Change by Stock
        </Typography.Text>
      ),
      children: (
        <div>
          {filterStockWiseGraphDataArr?.length > 0 ? (
            <>
              {wholeGraphData?.portfolioFilterData_spreadsheet_links?.map(
                (item, index) => (
                  <div className="mb-5" key={index}>
                    {Object.entries(item).map(([key, value]) => (
                      <LinkChildView key={key} name={key} value={value} />
                    ))}
                  </div>
                ),
              )}
              <Button
                onClick={() => {
                  changeFilterDisplayStatus("all", "cumulative_change");
                }}
              >
                Hide All
              </Button>
              <div className="w-full flex flex-row items-center	">
                <div
                  ref={dataAltDivRef}
                  className="w-1/5 h-[550px] overflow-y-auto"
                >
                  {filterStockWiseGraphDataArr?.map((item, ind) => (
                    <ListItem
                      key={ind}
                      type="cumulative_change"
                      data={item}
                      index={ind}
                    />
                  ))}
                </div>
                <div className="w-[2px] ml-[15px] h-[550px] bg-gray-300	" />
                <Row className="w-4/5 flex-col">
                  <ChartCard
                    key={lineCumulativePercentageChartConfig}
                    chartConfig={lineCumulativePercentageChartConfig}
                    chartType={"LINE"}
                  />
                </Row>
              </div>
            </>
          ) : (
            <Row className="w-full flex-col">
              <ChartCard
                key={lineCumulativePercentageChartConfig}
                chartConfig={lineCumulativePercentageChartConfig}
                chartType={"LINE"}
              />
            </Row>
          )}
        </div>
      ),
      style: panelStyle,
    },

    {
      key: "3",
      label: (
        <Typography.Text className="text-[16px] font-semibold mb-[20px]">
          Percent Change Whole Portfolio
        </Typography.Text>
      ),
      children: (
        <Row className="w-full flex-col">
          {wholeGraphData?.portfolioData_spreadsheet_links?.map(
            (item, index) => (
              <div className="mb-5" key={index}>
                {Object.entries(item).map(([key, value]) => (
                  <LinkChildView key={key} name={key} value={value} />
                ))}
              </div>
            ),
          )}
          <ChartCard
            key={linePercentWholeChartConfig}
            chartConfig={linePercentWholeChartConfig}
            chartType={"LINE"}
          />
        </Row>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: (
        <Typography.Text className="text-[16px] font-semibold mb-[20px]">
          Cumulative Percent Change Whole Portfolio
        </Typography.Text>
      ),
      children: (
        <Row className="w-full flex-col">
          {wholeGraphData?.portfolioData_spreadsheet_links?.map(
            (item, index) => (
              <div className="mb-5" key={index}>
                {Object.entries(item).map(([key, value]) => (
                  <LinkChildView key={key} name={key} value={value} />
                ))}
              </div>
            ),
          )}
          <ChartCard
            key={lineCumulativeWholeChartConfig}
            chartConfig={lineCumulativeWholeChartConfig}
            chartType={"LINE"}
          />
        </Row>
      ),
      style: panelStyle,
    },
    {
      key: "5",
      label: (
        <Typography.Text className="text-[16px] font-semibold mb-[20px]">
          Percent Change by Stock Group Portfolio
        </Typography.Text>
      ),
      children: (
        <div>
          {groupStockData?.length > 0 ? (
            <>
              {wholeGraphData?.portfolioStockGroupData_spreadsheet_links?.map(
                (item, index) => (
                  <div className="mb-5" key={index}>
                    {Object.entries(item).map(([key, value]) => (
                      <LinkChildView key={key} name={key} value={value} />
                    ))}
                  </div>
                ),
              )}
              <div className="w-full flex flex-row items-center	">
                <div
                  ref={dataAltDivRef}
                  className="w-1/5 h-[550px] overflow-y-auto"
                >
                  {groupStockData?.map((item, ind) => (
                    <GroupListItem
                      key={ind}
                      type="percentage_change"
                      data={item}
                      index={ind}
                    />
                  ))}
                </div>
                <div className="w-[2px] ml-[15px] h-[550px] bg-gray-300		" />
                <Row className="w-4/5 flex-col">
                  <ChartCard
                    key={linePercentGroupChartConfig}
                    chartConfig={linePercentGroupChartConfig}
                    chartType={"LINE"}
                  />
                </Row>
              </div>
            </>
          ) : (
            <Row className="w-full flex-col">
              <ChartCard
                key={linePercentGroupChartConfig}
                chartConfig={linePercentGroupChartConfig}
                chartType={"LINE"}
              />
            </Row>
          )}
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "6",
      label: (
        <Typography.Text className="text-[16px] font-semibold mb-[20px]">
          Cumulative Percent Change by Stock Group Portfolio
        </Typography.Text>
      ),
      children: (
        <div>
          {groupStockData?.length > 0 ? (
            <>
              {wholeGraphData?.portfolioStockGroupData_spreadsheet_links?.map(
                (item, index) => (
                  <div className="mb-5" key={index}>
                    {Object.entries(item).map(([key, value]) => (
                      <LinkChildView key={key} name={key} value={value} />
                    ))}
                  </div>
                ),
              )}
              <div className="w-full flex flex-row items-center	">
                <div
                  ref={dataAltDivRef}
                  className="w-1/5 h-[550px] overflow-y-auto"
                >
                  {groupStockData?.map((item, ind) => (
                    <GroupListItem
                      key={ind}
                      type="cumulative_change"
                      data={item}
                      index={ind}
                    />
                  ))}
                </div>
                <div className="w-[2px] ml-[15px] h-[550px] bg-gray-300		" />
                <Row className="w-4/5 flex-col">
                  <ChartCard
                    key={lineCumulativeGroupChartConfig}
                    chartConfig={lineCumulativeGroupChartConfig}
                    chartType={"LINE"}
                  />
                </Row>
              </div>
            </>
          ) : (
            <Row className="w-full flex-col">
              <ChartCard
                key={lineCumulativeGroupChartConfig}
                chartConfig={lineCumulativeGroupChartConfig}
                chartType={"LINE"}
              />
            </Row>
          )}
        </div>
      ),
      style: panelStyle,
    },
  ];

  return (
    <>
      <Row className="items-center mt-[10px] justify-between  w-[500px]">
        <Typography.Text className="text-[#696969]">Start Date</Typography.Text>
        <DatePicker
          className="w-[256px]"
          value={stockStartDate}
          onChange={setStockStartDate}
        />
      </Row>
      <Row className="items-center mt-[10px] justify-between  w-[500px]">
        <Typography.Text className="text-[#696969]">End Date</Typography.Text>
        <DatePicker
          className="w-[256px]"
          value={stockEndDate}
          onChange={setStockEndDate}
        />
      </Row>
      <Button
        type="primary"
        className="mr-4 bg-primaryDark mt-[20px]"
        onClick={() => {
          submitPortfolio("PortfolioPerformance", {
            start_date: stockStartDate
              ? dayjs(stockStartDate).format("YYYY-MM-DD")
              : "",
            end_date: stockEndDate
              ? dayjs(stockEndDate).format("YYYY-MM-DD")
              : "",
          });
        }}
        loading={isPortfolioLoading}
      >
        Run
      </Button>
      {!!wholeGraphData?.previewDate && (
        <Row>
          <Typography.Text className="text-[16px] font-semibold my-[20px]">
            Data last fetched on {wholeGraphData?.previewDate}
          </Typography.Text>
        </Row>
      )}
      <Collapse
        bordered={false}
        className="mt-[10px]"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: token.colorBgContainer,
        }}
        key={wholeGraphData?.portfolioData}
        items={getItems(panelStyle)}
      />
    </>
  );
};

export default StockPerformanceGroup;
