import { createBrowserRouter } from "react-router-dom";
import App from "@/App.jsx";
import Error from "@/components/Error";
import Login from "@/Pages/Login/index.jsx";
import Overview from "@/Pages/Overview/index.jsx";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/overview",
        element: <Overview />,
      },
    ],
  },
]);

export default appRouter;
