import { Button, Collapse, Row, theme, Typography } from "antd";
import { CaretRightOutlined, LogoutOutlined } from "@ant-design/icons";
import { googleLogout } from "@react-oauth/google";
import { setAccessToken } from "@/utils/index.js";
import { useNavigate } from "react-router-dom";
import StocksScreener from "@/Pages/Overview/StocksScreener/index.jsx";
import MyPortfolio from "@/Pages/Overview/MyPortfolio/index.jsx";
import Settings from "@/Pages/Overview/Settings/index.jsx";
import { CustomPortfolio } from "@/Pages/Overview/CustomPortfolio/index.jsx";
import useUser from "@/store/useUser";

const Overview = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { data: userData } = useUser();

  const panelStyle = {
    border: "none",
  };

  const getItems = (panelStyle) => [
    {
      key: "0",
      label: (
        <Typography.Text className="text-[16px] leading-[22px] font-medium">
          Settings
        </Typography.Text>
      ),
      children: <Settings />,
      style: panelStyle,
    },
    {
      key: "1",
      label: (
        <Typography.Text className="text-[16px] leading-[22px] font-medium">
          Stocks Screener
        </Typography.Text>
      ),
      children: <StocksScreener />,
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <Typography.Text className="text-[16px] leading-[22px] font-medium">
          My Portfolio
        </Typography.Text>
      ),
      children: <MyPortfolio />,
      style: panelStyle,
    },
    {
      key: "4",
      label: (
        <Typography.Text className="text-[16px] leading-[22px] font-medium">
          Custom Portfolio
        </Typography.Text>
      ),
      children: <CustomPortfolio />,
      style: panelStyle,
    },
  ];

  return (
    <Row className="bg-white p-[40px] flex-col">
      <Row className="items-center justify-between">
        <Typography.Text className="flex-1 text-[24px] leading-[22px] mb-[30px] font-medium">
          InvestPersonally
        </Typography.Text>
        <Typography.Text className="text-[18px] font-regular">
          {userData?.data?.email}
        </Typography.Text>
        <Button
          type="text"
          icon={<LogoutOutlined />}
          onClick={(e) => {
            googleLogout();
            e.preventDefault();
            navigate("/login");
            setAccessToken("");
            window.location.href = "/";
            localStorage.clear();
          }}
        >
          Log out
        </Button>
      </Row>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: token.colorBgContainer,
        }}
        items={getItems(panelStyle)}
      />
    </Row>
  );
};

export default Overview;
