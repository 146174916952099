import useLoading from "@/hooks/useLoading.js";
import environment from "@/environment/index.js";
import apiClient from "@/services/apiClient/index.js";
import { Button, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import useTransactions from "@/store/useTransactions.js";

const UploadTransaction = ({ onSuccess }) => {
  const { mutate: mutateTransactionData } = useTransactions();
  const {
    executeAction: handleUploadTransactionFile,
    loading: isUploadTransactionFileLoading,
  } = useLoading(async (fileList) => {
    try {
      const formData = new FormData();
      formData.set("transaction_files", fileList);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const url = `${environment.BASE_URL}/api/files/upload/transaction_files`;
      await apiClient.post(url, formData, config);
      message.success("Submit Successful.");
      await onSuccess?.();
      await mutateTransactionData?.();
    } catch (e) {
      message.error(
        typeof e?.response?.data?.message === typeof String()
          ? e?.response?.data?.message
          : "Something went wrong. Please try again in some time.",
      );
    }
    return false;
  });

  const props = {
    accept: ".csv",
    multiple: false,
    beforeUpload: async (file) => {
      await handleUploadTransactionFile(file);
      return false;
    },
  };

  return (
    <Upload {...props} showUploadList={false}>
      <Button
        icon={<UploadOutlined />}
        loading={isUploadTransactionFileLoading}
      >
        Upload Transaction file
      </Button>
    </Upload>
  );
};

export default UploadTransaction;
