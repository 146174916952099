import apiClient from "@/services/apiClient/index.js";

export const urls = {
  userDetails: `/api/userinfo`,
  getUserToken: (code) => `/api/google_auth?code=${code}`,
};

export default {
  getUserDetails: () => apiClient.get(urls.userDetails),
  submitUserDetails: (payload) => apiClient.post(urls.userDetails, payload),
  getUserToken: (code) => apiClient.get(urls.getUserToken(code)),
};
