/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Collapse,
  DatePicker,
  Row,
  Skeleton,
  Typography,
  message,
  theme,
} from "antd";
import {
  CaretRightOutlined,
  EyeFilled,
  EyeInvisibleFilled,
} from "@ant-design/icons";
import ChartCard from "@/components/ChartCard";
import { useEffect, useState } from "react";
import useUser from "@/store/useUser";
import useLoading from "@/hooks/useLoading";
import investServices from "@/services/apiServices/investServices";
import dayjs from "dayjs";

export const PerformanceAnalysis = ({ portfolio_id }) => {
  const { token } = theme.useToken();
  const panelStyle = {
    border: "none",
  };
  const [filterStockWiseGraphDataArr, setFilterStockWiseGraphDataArr] =
    useState([]);
  const [stockStartDate, setStockStartDate] = useState("");
  const [historyDate, setHistoryDate] = useState("");
  const [stockEndDate, setStockEndDate] = useState("");
  const [CumulativeGraphData, setCumulativeGraphData] = useState([]);
  const [PercentGraphData, setPercentGraphData] = useState([]);
  const [filterStockGraphArr, setFilterStockGraphArr] = useState([]);
  const { data: userData } = useUser();
  const [stockList, setStockList] = useState([]);
  const [stock_wise_data, setStock_wise_data] = useState({});

  const { executeAction: getData, loading: isLoading } = useLoading(
    async (is_history) => {
      try {
        let params = {
          start_date: dayjs(stockStartDate).format("YYYY-MM-DD"),
          end_date: dayjs(stockEndDate).format("YYYY-MM-DD"),
          spreadsheet_url: userData?.data?.settings?.spreadsheet_url,
          portfolio_type: "custom",
          portfolio_id,
        };
        if (is_history) {
          params.is_history = true;
        }
        const data = await investServices.getPerformanceFileLinks(params);
        if (!is_history) {
          message.success("Data fetched successfully");
        }
        if (data?.data?.stock_wise_data) {
          setStock_wise_data(data?.data?.stock_wise_data);
        } else {
          setStock_wise_data([]);
        }
        if (data?.data?.date) {
          setHistoryDate(data?.data?.date);
        } else {
          setHistoryDate("");
        }
        if (data?.data?.portfolio_cumulative_change) {
          const updatedArr = data?.data?.portfolio_cumulative_change?.map(
            (itm) => ({ ...itm, category: "Cumulative Change" }),
          );
          setCumulativeGraphData(updatedArr);
        } else {
          setCumulativeGraphData([]);
        }
        if (data?.data?.portfolio_percentage_change) {
          const updatedArr = data?.data?.portfolio_percentage_change?.map(
            (itm) => ({ ...itm, category: "Percentage Change" }),
          );

          setPercentGraphData(updatedArr);
        } else {
          setPercentGraphData([]);
        }
      } catch (e) {
        message.error(
          typeof e?.response?.data?.message === typeof String()
            ? e?.response?.data?.message
            : "Something went wrong. Please try again in some time.",
        );
      }
    },
  );

  useEffect(() => {
    getData(true);
  }, []);

  useEffect(() => {
    if (stock_wise_data) {
      const stockWiseArr = Object.entries(stock_wise_data).map(
        ([key, value]) => ({
          key,
          value,
        }),
      );
      const graphArr = [];
      const stockSetArr = [];
      stockWiseArr?.map((item) => {
        stockSetArr.push({ value: item?.key, label: item?.key });
        item?.value?.map((itm) => {
          graphArr.push({ ...itm, type: item?.key });
        });
      });
      if (stockList?.length == 0) setStockList(stockSetArr);
      setFilterStockWiseGraphDataArr(stockWiseArr);
      setFilterStockGraphArr(graphArr);
    }
  }, [stock_wise_data, stockList?.length]);

  const getFilterStockPrGraphData = (dataSet, type) => {
    if (type == "cumulative_change") {
      return dataSet?.filter((item) => !item?.hideCCData);
    } else if (type == "percentage_change") {
      return dataSet?.filter((item) => !item?.hidePCData);
    }
    return dataSet;
  };
  const lineCumulativeWholeChartConfig = {
    data: CumulativeGraphData || [],
    xField: "date",
    yField: "percentage_change",
    colorField: "category",
    legend: false,
  };
  const lineCumulativePercentageChartConfig = {
    data: getFilterStockPrGraphData(filterStockGraphArr, "cumulative_change"),
    xField: "date",
    yField: "cumulative_change",
    colorField: "type",
  };
  const linePercentChangeChartConfig = {
    data: getFilterStockPrGraphData(filterStockGraphArr, "percentage_change"),
    xField: "date",
    yField: "percentage_change",
    colorField: "type",
  };
  const linePercentWholeChartConfig = {
    data: PercentGraphData || [],
    xField: "date",
    yField: "percentage_change",
    colorField: "category",
    legend: false,
  };

  const ListItem = ({ data, index, type }) => {
    return (
      <div className="flex justify-start items-center py-2 border-b border-gray-200">
        <Typography.Text className="text-[14px] flex-1 font-normal">
          {data?.key}
        </Typography.Text>
        {filterStockWiseGraphDataArr?.length > 1 && (
          <div
            className="p-2 mr-3"
            onClick={() => changeFilterDisplayStatus(index, type)}
          >
            {type == "cumulative_change" ? (
              data?.hideCCData ? (
                <EyeInvisibleFilled />
              ) : (
                <EyeFilled />
              )
            ) : data?.hidePCData ? (
              <EyeInvisibleFilled />
            ) : (
              <EyeFilled />
            )}
          </div>
        )}
      </div>
    );
  };

  const changeFilterDisplayStatus = (index, type) => {
    const localArr = [...filterStockWiseGraphDataArr];
    const localArrGraph = [...filterStockGraphArr];
    if (index == "all") {
      if (type == "cumulative_change") {
        localArr?.map((itm, ind) => {
          localArr[ind].hideCCData = true;
        });
      } else if (type == "percentage_change") {
        localArr?.map((itm, ind) => {
          localArr[ind].hidePCData = true;
        });
      }
      localArrGraph?.map((itm, ind) => {
        if (type == "cumulative_change") {
          localArrGraph[ind].hideCCData = true;
        } else if (type == "percentage_change") {
          localArrGraph[ind].hidePCData = true;
        }
      });
    } else {
      if (type == "cumulative_change") {
        localArr[index].hideCCData = !localArr?.[index]?.hideCCData;
      } else if (type == "percentage_change") {
        localArr[index].hidePCData = !localArr?.[index]?.hidePCData;
      }

      localArrGraph?.map((itm, ind) => {
        if (itm.type == localArr[index].key) {
          if (type == "cumulative_change") {
            localArrGraph[ind].hideCCData = !localArrGraph?.[ind]?.hideCCData;
          } else if (type == "percentage_change") {
            localArrGraph[ind].hidePCData = !localArrGraph?.[ind]?.hidePCData;
          }
        }
      });
    }
    setFilterStockWiseGraphDataArr(localArr);
    setFilterStockGraphArr(localArrGraph);
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <Typography.Text className="text-[16px] font-semibold mb-[20px]">
          Percent Change by Stock
        </Typography.Text>
      ),
      children: (
        <div>
          {filterStockWiseGraphDataArr?.length > 0 ? (
            <>
              <Button
                onClick={() => {
                  changeFilterDisplayStatus("all", "percentage_change");
                }}
              >
                Hide All
              </Button>
              <div className="w-full flex flex-row items-center mt-5	">
                <div className="w-1/5 h-[550px] overflow-y-auto">
                  {filterStockWiseGraphDataArr?.map((item, ind) => (
                    <ListItem
                      key={ind}
                      type="percentage_change"
                      data={item}
                      index={ind}
                    />
                  ))}
                </div>
                <div className="w-[2px] ml-[15px] h-[550px] bg-gray-300		" />
                <Row className="w-4/5 flex-col">
                  <ChartCard
                    key={linePercentChangeChartConfig}
                    chartConfig={linePercentChangeChartConfig}
                    chartType={"LINE"}
                  />
                </Row>
              </div>
            </>
          ) : (
            <Row className="w-full flex-col">
              <ChartCard
                key={linePercentChangeChartConfig}
                chartConfig={linePercentChangeChartConfig}
                chartType={"LINE"}
              />
            </Row>
          )}
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <Typography.Text className="text-[16px] font-semibold mb-[20px]">
          Cumulative Percent Change by Stock
        </Typography.Text>
      ),
      children: (
        <div>
          {filterStockWiseGraphDataArr?.length > 0 ? (
            <>
              <Button
                onClick={() => {
                  changeFilterDisplayStatus("all", "cumulative_change");
                }}
              >
                Hide All
              </Button>
              <div className="w-full flex flex-row items-center	mt-5">
                <div className="w-1/5 h-[550px] overflow-y-auto">
                  {filterStockWiseGraphDataArr?.map((item, ind) => (
                    <ListItem
                      key={ind}
                      type="cumulative_change"
                      data={item}
                      index={ind}
                    />
                  ))}
                </div>
                <div className="w-[2px] ml-[15px] h-[550px] bg-gray-300	" />
                <Row className="w-4/5 flex-col">
                  <ChartCard
                    key={lineCumulativePercentageChartConfig}
                    chartConfig={lineCumulativePercentageChartConfig}
                    chartType={"LINE"}
                  />
                </Row>
              </div>
            </>
          ) : (
            <Row className="w-full flex-col">
              <ChartCard
                key={lineCumulativePercentageChartConfig}
                chartConfig={lineCumulativePercentageChartConfig}
                chartType={"LINE"}
              />
            </Row>
          )}
        </div>
      ),
      style: panelStyle,
    },

    {
      key: "3",
      label: (
        <Typography.Text className="text-[16px] font-semibold mb-[20px]">
          Percent Change Whole Portfolio
        </Typography.Text>
      ),
      children: (
        <Row className="w-full flex-col">
          <ChartCard
            key={linePercentWholeChartConfig}
            chartConfig={linePercentWholeChartConfig}
            chartType={"LINE"}
          />
        </Row>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: (
        <Typography.Text className="text-[16px] font-semibold mb-[20px]">
          Cumulative Percent Change Whole Portfolio
        </Typography.Text>
      ),
      children: (
        <Row className="w-full flex-col">
          <ChartCard
            key={lineCumulativeWholeChartConfig}
            chartConfig={lineCumulativeWholeChartConfig}
            chartType={"LINE"}
          />
        </Row>
      ),
      style: panelStyle,
    },
  ];
  return (
    <>
      <Row className="flex-col">
        <Row className="items-center mt-[10px] justify-between  w-[500px]">
          <Typography.Text className="text-[#696969]">
            Start Date
          </Typography.Text>
          <DatePicker
            className="w-[256px]"
            value={stockStartDate}
            onChange={setStockStartDate}
          />
        </Row>
        <Row className="items-center mt-[10px] justify-between  w-[500px]">
          <Typography.Text className="text-[#696969]">End Date</Typography.Text>
          <DatePicker
            className="w-[256px]"
            value={stockEndDate}
            onChange={setStockEndDate}
          />
        </Row>
        <Button
          loading={isLoading}
          type="primary"
          disabled={!stockStartDate || !stockEndDate}
          onClick={() => {
            getData();
          }}
          className="mt-5 mb-5 w-[100px] bg-primaryDark"
        >
          Get Data
        </Button>
        {!!historyDate && (
          <Row>
            <Typography.Text className="text-[16px] font-semibold my-[20px]">
              Data last fetched on {historyDate}
            </Typography.Text>
          </Row>
        )}
        {isLoading ? (
          <Skeleton key={1} paragraph={{ rows: 0 }} />
        ) : (
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined className="mt-2" rotate={isActive ? 90 : 0} />
            )}
            style={{
              background: token.colorBgContainer,
            }}
            items={getItems(panelStyle)}
          />
        )}
      </Row>
    </>
  );
};
